export const th = {
  no: "ลำดับ",
  No: "ไม่ได้",
  Yes: "ได้",
  lock: "ล็อค",
  lockNo: "ปกติ",
  open: "เปิด",
  close: "ปิด",
  to: "ถึง",
  edit: "แก้ไข",
  confirm: "ยืนยัน",
  cancel: "ยกเลิก",
  submit: "ตกลง",
  update: "อัปเดต",
  reset: "ล้างค่า",
  active: "สถานะ",
  deactive: "ปิดการใช้งาน",
  delete: "ลบ",
  message: "ข้อความ",
  noRecordFound: "ไม่พบข้อมูล",
  loading: "กำลังโหลด...",
  copy: "คัดลอก",
  all: "ทั้งหมด",
  team: "ทีม",
  credit: "เครดิต",
  detail: "รายละเอียด",
  status: "สถานะ",
  information: "ข้อมูล",
  odds: "ราคาต่อรอง",
  stake: "เดิมพัน",
  winlose: "ได้-เสีย",
  running: "รอคิดได้-เสีย",
  total: "รวม",
  setting: "ตั้งค่า",
  mark: "มาร์ก",
  cancelMark: "ยกเลิกมาร์ก",
  win: "ชนะ",
  lose: "แพ้",
  draw: "เสมอ",
  allActive: "สถานะทั้งหมด",
  payout: "การจ่ายเงิน",
  payoutTotal: "การจ่ายเงินทั้งหมด",
  type: "ประเภท",
  betting: "การแทง",
  language: {
    title: "ภาษา",
    english: "ภาษาอังกฤษ",
    thai: "ภาษาไทย",
  },
  time: {
    title: "เวลา",
    fullTime: "เต็มเวลา",
    firstTime: "ครึ่งแรก",
    firstHalf: "ครึ่งแรก",
    halfTime: "พักครึ่ง",
  },
  day: {
    date: "วันที่",
    selectDate: "เลือกวันที่",
    toDate: "ถึงวันที่",
    today: "วันนี้",
    yesterday: "เมื่อวาน",
    currentWeek: "สัปดาห์นี้",
    lastWeek: "สัปดาห์ที่ผ่านมา",
    currentMonth: "เดือนนี้",
    lastMonth: "เดือนที่ผ่านมา",
  },
  user: {
    title: "ผู้ใช้",
    username: "ชื่อผู้ใช้",
    fullname: "ชื่อ",
    loginName: "ชื่อล็อคอิน",
    password: "รหัสผ่าน",
    phone: "เบอร์โทศัพท์",
    lastLogin: "เข้าระบบล่าสุด",
    signIn: "เข้าสู่ระบบ",
    LogOut: "ออกจากระบบ",
  },
  level: {
    title: "ระดับผู้ใช้งาน",
    under: "ภายใต้",
    member: "สมาชิก",
    agent: "เอเยนต์",
    master: "มาสเตอร์",
    senior: "ซีเนียร์",
    super: "ซุปเปอร์",
    company: "คอมพานี",
    0: "",
    1: "สมาชิก",
    2: "เอเยนต์",
    3: "มาสเตอร์",
    4: "ซีเนียร์",
    5: "ซุปเปอร์",
    6: "คอมพานี",
    memberTotal: "สมาชิก",
    agentTotal: "เอเยนต์",
    masterTotal: "มาสเตอร์",
    seniorTotal: "ซีเนียร์",
    superTotal: "ซุปเปอร์",
    companyTotal: "คอมพานี",
  },
  totalBet: {
    title: "ยอดพนันสุทธิ",
    simple: "ยอดพนัน",
    ballStep: {
      title: "ยอดพนันบอลสเต็ป",
      ballStep: "บอลสเต็ป",
    },
    singleBet: "ยอดพนันบอลเต็ง",
    memberOutstanding: "ยอดพนันตามสมาชิก",
    sideMenu: {
      simpleHdpOu: "ยอดพนัน HDP/OU",
      simpleHtHdpOu: "ยอดพนัน HT HDP/OU",
      simple1X2Oe: "ยอดพนัน 1X2/OE",
    },
  },
  memberMgmt: {
    title: "ระบบจัดการสมาชิก",
    newAccount: {
      titleContent: "สร้างผู้ใช้ใหม่",
      title: "สร้างผู้ใช้ใหม่",
      userInformation: "ข้อมูลผู้ใช้",
      positionTaking: "การถือหุ้น",
      comm: "คอมมิชชั่น",
      comms: "คอมมิชชั่น",
      couple: "คู่",
      currency: "สกุลเงิน",
      positionSetting: "ตั้งค่าถือหุ้น",
      available: "ผ่าน",
      unavailable: "ไม่ผ่าน",
      status: "สถานะปิด/เปิด",
      remaining: "ดูดของ",
      forceShare: "บังคับหุ้น",
      accountId: "ชื่อล็อคอิน",
      passwordIsRequired: "กรุณากรอกรหัสผ่าน",
    },
    accountList: {
      title: "รายชื่อผู้ใช้",
      changePassword: "เปลี่ยนรหัสผ่าน",
      inCaseChangePasswordOnly: "สำหรับเปลี่ยนรหัสผ่านเท่านั้น",
    },
    betSetting: {
      title: "ตั้งค่าการแทง",
      setting: "ตั้งค่า",
      update: "แก้ไข",
    },
    suspend: "ระงับ",
    no: "ปกติ",
    odds: "ตังค์",
    betting: {
      title: "ตั้งค่าการแทง",
      bettingSetting: "ตั้งค่าการแทง",
    },
    commission: {
      title: "จัดการคอมมิชชั่น",
      commission: "คอมมิชชั่น",
    },
    oddsType: "ประเภทราคา",
    minBet: "แทงขั้นต่ำต่อบิล",
    maxBet: "แทงสูงสุดต่อบิล",
    maxPerMatch: "แทงสูงสุดต่อคู่",
    maxPayout: "จ่ายสูงสุดต่อบิล",
    stepMin: "คู่ต่ำสุดต่อบิล",
    stepMax: "คู่สูงสุดต่อบิล",
    single: "แทงเต็ง",
    fh: "แทงครึ่งแรก",
    ht: "แทงพักครึ่ง",
    ticket: "สถานะตั๋ว",
    ticketStatus: "สถานะตั๋ว",
    slip: "ปริ้นสลิป",
    printSlip: "ปริ้นสลิป",
    auto: "ปกติ",
    remaining: "ดูดของ",
    forceShare: "บังคับหุ้น",
    credit: {
      title: "จัดการเครดิต",
      maxCredit: "เครดิตสูงสุด",
      creditCurrent: "ยอดเครดิต",
      topUp: "เติมเงิน",
      withdraw: "ถอนเงิน",
      record: "บันทึกการเติมเงิน",
      topUpBy: "เติมเงินจาก",
      topUpTo: "สมาชิก",
      creditAmount: "ยอดเติมเงิน",
      beforeAmount: "ยอดก่อนเติมเงิน",
      afterAmount: "After Amount",
      activeBy: "ผู้ดำเนินการ",
      topUpTime: "เวลาเติมเงิน",
    },
    presetPT: {
      title: "จัดการหุ้นสมาชิกใหม่ ",
      ptSetting: "เลือกประเภท",
    },
    effectivePT: {
      title: "จัดการหุ้นสมาชิกเก่า",
      status: "สถานะปิด/เปิด",
    },
  },
  report: {
    title: "รายงานแพ้ชนะ",
    winloseSimple: "แพ้ชนะแบบธรรมดา",
    statement: "รายงานการเงิน",
    matchResult: "ผลการแข่งขัน",
    cancelBetList: "รายการที่ถูกยกเลิก",
    result: {
      title: "ผลการแข่งขัน",
      firstHalfScore: "ผลการแข่งขันครึ่งแรก",
      fullTimeScore: "ผลการแข่งขันเต็มเวลา",
      allTitle: "รายวัน",
      pendingTitle: "ผลค้าง",
      id: "ไอดี",
      time: "เวลา",
      match: "คู่แข่งขัน",
      halftime: "ผลครึ่งแรก",
      fulltime: "ผลเต็มเวลา",
      edit: "แก้ไขผลการแข่งขัน",
      status: "สถานะ",
      all: "ทั้งหมด",
      pending: "รอผล",
      incoming: "กำลังจะแข่ง",
      fh: "FH",
      ht: "HT",
      play1h: "เวลาแข่งขัน 1 ชม.",
      play2h: "เวลาแข่งขัน 2 ชม.",
      refundFT: "รีฟัน FT",
      refund1H: "รีฟัน 1 ชม.",
      complete: "คิดผลแล้ว",
      updateMatchResultSuccess: "แก้ไขข้อมูลสำเร็จ",
    },
  },
  account: {
    title: "บัญชี",
    announcement: "ประกาศ",
    myProfile: "ข้อมุลการเงิน",
    balanceInformation: {
      title: "ข้อมูลเครดิต",
      outstanding: "ยอดแทงค้าง{level}",
      totalOutstanding: "ยอดแทงค้างทั้งหมด",
      creditSuper: "เครดิต ซูเปอร์",
      creditSenior: "เครดิต ซีเนียร์",
      creditMaster: "เครดิต มาสเตอร์",
      creditAgent: "เครดิต เอเยนต์",
      creditMember: "เครดิต สมาชิก",
      superPlayableLimit: "ซุปเปอร์แทงได้สูงสุด",
      seniorPlayableLimit: "ซีเนียร์แทงได้สูงสุด",
      masterPlayableLimit: "มาสเตอร์แทงได้สูงสุด",
      agentPlayableLimit: "เอเย่นท์แทงได้สูงสุด",
      memberPlayableLimit: "เมมเบอร์แทงได้สูงสุด",
      superAmount: "จำนวน ซุปเปอร์ (active/deactive)",
      seniorAmount: "จำนวน ซีเนียร์ (active/deactive)",
      masterAmount: "จำนวน มาสเตอร์ (active/deactive)",
      agentAmount: "จำนวน เอเย่นท์ (active/deactive)",
      memberAmount: "จำนวน เมมเบอร์ (active/deactive)",
    },
    changePassword: {
      title: "เปลี่ยนรหัสผ่าน",
      currentPassword: "รหัสผ่านปัจจุบัน",
      newPassword: "รหัสผ่านใหม่",
      confirmPassword: "ยืนยันรหัสผ่านใหม่",
    },
  },
  alias: {
    title: "ผู้ใช้ร่วม",
    aliasManagement: "จัดการผู้ช่วย",
  },
  printSlip: {
    title: "ปริ้นสลิป",
    howToSetup: "วิธีการติดตั้งระบบปริ้นสลิป",
    howToUsePrintSlipSystem: "วิธีการติดตั้งระบบปริ้นสลิป",
    updatePrintSlip: "วิธีการอัพเดทปริ้นสลิป",
    updatePrintSlipApplication: "วิธีการอัพเดทปริ้นสลิป",
    downloadPrintSlip: "ติดตั้งปริ้นสลิป v3.0.1",
    howToUse: "วิธีการใช้งานระบบปริ้นสลิป",
  },
  errorMessage: {
    currentPasswordErrorReq: "กรุณากรอกรหัสผ่านปัจจุบัน",
    currentPasswordErrorMin: "กรุณากรอกรหัสผ่านปัจจุบันอย่างน้อย 6 ตัวอักษร",
    currentPasswordErrorNotCorrect: "รหัสผ่านปัจจุบันไม่ถูกต้อง",
    changePasswordFailed: "ไม่สามารถเปลี่ยนรหัสผ่านได้",
    newPasswordErrorReq: "กรุณากรอกรหัสผ่านใหม่",
    newPasswordErrorMin: "กรุณากรอกรหัสผ่านใหม่อย่างน้อย 6 ตัวอักษร",
    confirmPasswordErrorReq: "กรุณายืนยันรหัสผ่านใหม่",
    confirmPasswordErrorMin: "กรุณากรอกรหัสผ่านใหม่อย่างน้อย 6 ตัวอักษร",
    confirmPasswordErrorRefine: "การยืนยันรหัสใหม่ไม่ตรงกัน",
  },
  successMessage: {
    changePasswordSuccess: "เปลี่ยนรหัสผ่านใหม่สำเร็จ",
  },
  ticketStatus: {
    cancel: "ยกเลิก",
    waiting: "รอคอนเฟิร์ม",
    running: "รอคิดได้-เสีย",
    complete: "สำเร็จ",
  },
};
